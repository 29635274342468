  import {Box,} from "@material-ui/core";
  import React, { useEffect, useState } from "react";
  import styled from "styled-components";
  import useMatchBreakpoints from "../../hooks/useMatchBreakpoints";
  import { useTranslation } from "react-i18next";
  import { Outlet } from "react-router-dom";
import Header from "./component/header";
import Footer from "./component/Footer";
import Slide from "./component/slide";
  

  
  
  const MainPanel = styled(Box)<{ isMobile?: boolean }>`
     /* min-width: ${(props) => (props.isMobile?"350px":"750px")}; */
  `;
  
  const Container = styled(Box)<{ isMobile?: boolean }>`
        /* @media (max-width: 750px) {
          padding-top: 1.04rem;
        }
  
        @media (min-width:750px){
            margin-top: -0.95rem;
        } */
  `;

  const DesktopComponent: React.FC = () => {
    const { isDesktop, isMobile } = useMatchBreakpoints();
    const { t, i18n } = useTranslation();
    const [loaded, setLoaded] = useState(false);


    return (
        
      
       <MainPanel isMobile={!isDesktop} display="flex" flex={1} flexDirection="column">
           
           
          <Header></Header>
    
          <Box display={'flex'} flex={1} >
            <Box display={'flex'} flex={1} className="mt-[72px]">
                <Container  pb={2}  isMobile={!isDesktop}   display="flex" flex={1} flexDirection="column">
                  <Outlet />
                </Container>
            </Box>

          </Box>
          <Footer></Footer> 
        
      </MainPanel>
      
       
    
     
    );
};

export default DesktopComponent;
