import {
    BottomNavigation,
    BottomNavigationAction,
    Box,
    Button,
  } from "@material-ui/core";
  import React, { useEffect, useState } from "react";
  import styled from "styled-components";
  import useMatchBreakpoints from "../../hooks/useMatchBreakpoints";
  import { useTranslation } from "react-i18next";
  import { Outlet } from "react-router-dom";
import Mobileheader from "./component/Mobileheader";
import MobileFooter from "./component/MobileFooter";

  
  
  const MainPanel = styled(Box)`
 
  `;
  
  const Container = styled(Box)`
  `;
  
  
  const MobileComponent: React.FC = () => {
    const { isDesktop } = useMatchBreakpoints();
    const { t, i18n } = useTranslation();
    return (
      <MainPanel display="flex" flex={1} flexDirection="column">
      
       <Mobileheader></Mobileheader>
       <Container className="mt-[1rem]"    display="flex" flex={1} flexDirection="column">
        <Outlet />
       </Container>
       <MobileFooter></MobileFooter>

      </MainPanel>
    );
};

export default MobileComponent;
