import { Box, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import 'react-awesome-slider/dist/styles.css';
import { useNavigate } from "react-router-dom";
import styles from './css/wapzhuye.module.css';
import Carousel from "nuka-carousel/lib/carousel";
import useMatchBreakpoints from "../../../hooks/useMatchBreakpoints";


const StylePanel=styled(Box)`
      @media (max-width: 750px) {
      } 

      @media (min-width:750px){
        .page{
            svg{
              margin-left: 5px;
              width:10px;
              height:10px;
            }
        }
      }
      .containerStyle{
        height: 8.04rem !important;
     }
   


`
const BtnMore = styled.button<any>`
      width: 1.75rem;
      height: 0.56rem;
      background: linear-gradient(135deg,#36d0ac, #60f770);
      border-radius: 0.05rem;
      font-size:0.24rem ;
`;

const MoblieHome: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showDes,setShowDes] = useState(false);
  const { isDesktop } = useMatchBreakpoints();

  const [currentSlide, setCurrentSlide] = useState(0);
  const handlePageClick = (index:number) => {
    setCurrentSlide(index);
  };

  const renderCustomControls = ({currentSlide}:any) => {


         return <div className="flex-col">
            {
              currentSlide==0 &&  <div   className={`flex-col items-center text-center mb-[0.63rem]  ${styles['fade-in']} `}>
              <div >
                  <h1 className="text-white text-[0.64rem] font-normal">Cornerstone for DeSoc</h1>
                  <p className="text-white text-[0.24rem] ">The first decentralized access-control protocol in Web 3.0</p>
              </div>
              
              <BtnMore onClick={()=>{
                window.location.href=`${window.location.origin}/#/Learn/Protocol`
              }} className="mt-[0.29rem] text-white">Explore more</BtnMore>
            </div>
            }     

            { currentSlide==1 &&  <div       className={`flex-col items-center  mb-[0.63rem]  ${styles['fade-in1']} `}>
              <div >
                <h1 className="text-white  text-[0.48rem]">WEB 3.0 users' owndership is<br/> protected</h1>
              </div>
              
              <BtnMore onClick={()=>{
                // window.location.href="https://elfinguard.org/#/Learn/Protocol"
              }} className="mt-[0.29rem] text-white">Explore more</BtnMore>
            </div>
            }   

             {currentSlide==2 &&  <div   className={`flex-col items-center  mb-[0.63rem]   ${styles['fade-in2']} `}>
              <div >
                <h1 className="text-white text-[0.48rem] font-normal">Blockchain-based<br/> and programmable in EVM</h1>
              </div>
              
              <BtnMore onClick={()=>{
                // window.location.href="https://elfinguard.org/#/Learn/Protocol"
              }} className="mt-[0.29rem] h-[56px] w-[175px] text-white">Explore more</BtnMore>
            </div>
            }                 
                                
          <div className="justify-center flex-row mb-[0.6rem]">
                                          
                                          {[...Array(3)].map((_, index) => (
                                            <div     
                                              key={index}
                                              style={{
                                              display: 'flex',
                                              justifyContent:'center',
                                              alignItems: 'center',
                                              width: '0.12rem',
                                              height: '0.12rem',
                                              borderRadius: '50%',
                                              // border: currentSlide === index ? '1px solid #fff' : '',
                                              padding:currentSlide === index ? '2px' : '',
                                              margin: '0 10px',
                                              cursor: 'pointer',
                                              }}>
                                                <div
                                                  key={index}
                                                  style={{
                                                    
                                                    width: '12px',
                                                    height: '12px',
                                                    borderRadius: '50%',
                                                    background: currentSlide === index ? '#fff' : 'gray',
                                                  }}
                                                  onClick={() => handlePageClick(index)}
                                                >
                                                </div>
                                              </div>
                                          ))}
                                  
          </div>
        </div>


  };
  return (
    <StylePanel mb={5} className={`${styles['page']} ${styles['space-y-80']}`}  display="flex" flex={1} flexDirection='column'>
   <div className={`flex-col ${styles['page']}`}>
      <div className={`flex-col flex-auto ${styles['group']} ${styles['space-y-80']}`}>
        <div className={`flex-col`}>
          <div className={`flex-col ${styles['section']} ${styles['space-y-29']}`}>
    

            {/* <div className="flex flex-1 w-full h-full"> */}
            <Carousel 
                    autoplay
                    className="flex containerStyle"
                    wrapAround
                    adaptiveHeight
                    defaultControlsConfig={{
                   
                      pagingDotsContainerClassName:"page",
                      pagingDotsClassName:"page",
                      nextButtonText:<></>,
                      prevButtonText:<></>,
                      prevButtonStyle:{
                        display: 'none'
                      },
                      nextButtonStyle: {
                        display: 'none'
                      }
                      
                    }}
                    slideIndex={currentSlide}
                    afterSlide={setCurrentSlide}
                    renderBottomCenterControls={renderCustomControls}
                    // renderCenterCenterControls={renderCustomControls}
                    
                    >
                        <img className="w-full h-full " src="image/750/home/banner1.png" alt="image/750/home/banner1.png" />
                        <img className="w-full h-full" src="image/750/home/banner2.png" alt="image/750/home/banner2.png" />
                        <img className="w-full h-full"  src="image/750/home/banner3.png" alt="image/750/home/banner3.png" />
                    </Carousel>
          
          
          
          {/* </div> */}

          </div>
          <div className={`flex-col ${styles['group_7']}`}>
            <div className={`flex-col items-center ${styles['group_8']} ${styles['space-y-15']}`}>
              <span className={`${styles['font_3']}`}>Our Innovative Features</span>
              <div className={`${styles['section_6']} ${styles['view']}`}></div>
            </div>
            <div className={`flex-col ${styles['group_9']} ${styles['space-y-38']}`}>
              <div data-wow-duration="1s" data-wow-delay="0.2s" className={`wow fadeInUp flex-row  ${styles['section_7']} ${styles['space-x-53']}`}>
                <img
                  className={`shrink-0 ${styles['image_25']}`}
                  src="image/750/home/编组 3.png"
                />
                <div className={`flex-col flex-auto ${styles['group_10']} ${styles['space-y-17']}`}>
                  <span className={`self-start ${styles['font_4']}`}>Privacy</span>
                  <span className={`${styles['font_5']} ${styles['text_4']}`}>
                  For the target audience' eyes only. The protocols is hardware-based and reliable. It doesn’t rely on law, morality, discipline, or human-controlled permissions.
                  </span>
                </div>
              </div>
              <div data-wow-duration="1s" data-wow-delay="0.4s"  className={`wow fadeInUp flex-row ${styles['section_7']} ${styles['space-x-53']}`}>
                <img
                  className={`shrink-0 ${styles['image_25']}`}
                  src="image/750/home/编组 3.png"                />
                <div className={`flex-col flex-auto ${styles['space-y-17']}`}>
                  <span className={`self-start ${styles['font_4']}`}>Blockchain-based</span>
                  <span className={`${styles['font_5']} ${styles['text_4']}`}>
                    Easy to program solidity.The permissions are defined by smart contracts and on-chain states
                  </span>
                </div>
              </div>
              <div data-wow-duration="1s" data-wow-delay="0.6s"  className={`wow fadeInUp flex-row ${styles['section_7']} ${styles['space-x-53']}`}>
                <img
                  className={`shrink-0 ${styles['image_25']}`}
                  src="image/750/home/编组 3.png"                />
                <div className={`flex-col flex-auto ${styles['space-y-17']}`}>
                  <span className={`self-start ${styles['font_4']}`}>Robust</span>
                  <span className={`${styles['font_5']} ${styles['text_4']}`}>
                    No single-point failure.Content delivery and permission check are both decentralized
                  </span>
                </div>
              </div>
            </div>
            <div data-wow-duration="1s" data-wow-delay="0.2s" className={`wow fadeInUp flex-col ${styles['group_11']} ${styles['space-y-80']}`}>
              <div className={`flex-col`}>
                <div className={`flex-col justify-start items-center relative ${styles['section_8']}`}>
                  <img className="ssm:h-[4.87rem]  md:h-[5.87rem] w-full" src="image/750/home/编组 26.png"/>
                </div>

                
                <div className={`flex-col ${styles['section_13']} ${styles['space-y-27']}`}>
                  <span className={`self-center ${styles['font_3']} ${styles['text_6']}`}>Why Elfinguard</span>
                  <div className={`flex-col items-center text-center ${styles['space-y-17']}`}>
                    <span className={`${styles['font_6']}`}>
                      Protect content’s value and ensure ownership in decentralized storage
                    </span>
                    <span className={`${styles['font_6']} ${styles['text_7']}`}>
                      Access-control brings authors incentives and earings
                    </span>
                    <span className={`${styles['font_6']}`}>
                      ElfinGuard enables new business models for the crypto world
                    </span>
                  </div>
                </div>
              </div>
              <div className={`flex-col ${styles['section_14']}`}>
                <div className={`flex-col justify-start items-center relative ${styles['group_15']}`}>
                  <div className={`${styles['section_20']}`}></div>
                  <img
                    className={`${styles['image_38']} ${styles['pos_39']}`}
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/63a7fdfb5a7e3f0310febbfd/63ad399a717ee30011337499/16889566511541448296.png"
                  />
                  <div className={`flex-col justify-start ${styles['section_15']} ${styles['pos_38']}`}>
                    <div className={`flex-col ${styles['section_16']}`}>
                      <div className={`flex-row ${styles['group_16']}`}>
                        <span className={`self-start ${styles['font_7']} ${styles['text_8']}`}>Consumer</span>
                        <img
                          className={`${styles['image_31']} ${styles['image_32']}`}
                          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/63a7fdfb5a7e3f0310febbfd/63ad399a717ee30011337499/1ab1bb35d0907fe89437d13cbf5760bd.png"
                        />
                        <img
                          className={`${styles['image_31']} ${styles['image_33']}`}
                          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/63a7fdfb5a7e3f0310febbfd/63ad399a717ee30011337499/61f649777f52f61125ca09d6d26fdd7c.png"
                        />
                        <img
                          className={`${styles['image_31']} ${styles['image_34']}`}
                          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/63a7fdfb5a7e3f0310febbfd/63ad399a717ee30011337499/62220298994cbab843e34aa4558898f2.png"
                        />
                      </div>
                      <div className={`flex-row ${styles['group_17']}`}>
                        <div className={`flex-col items-center ${styles['section_17']}`}>
                          <span className={`${styles['font_7']} ${styles['text_9']}`}>content</span>
                          <img
                            className={`${styles['image_35']}`}
                            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/63a7fdfb5a7e3f0310febbfd/63ad399a717ee30011337499/16889566514827880297.png"
                          />
                        </div>
                        <div className={`self-center ${styles['section_18']}`}></div>
                        <div className={`self-start ${styles['section_19']}`}></div>
                      </div>
                      <div className={`flex-row justify-between ${styles['group_18']}`}>
                        <span className={`self-start ${styles['font_7']} ${styles['text_10']}`}>Producer</span>
                        <div
                          className={`flex-col items-start self-center ${styles['group_19']} ${styles['space-y-8']}`}
                        >
                          <img
                            className={`${styles['image_36']}`}
                            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/63a7fdfb5a7e3f0310febbfd/63ad399a717ee30011337499/f14f2bbdaf3e91b917c3471442e56f17.png"
                          />
                          <img
                            className={`${styles['image_37']}`}
                            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/63a7fdfb5a7e3f0310febbfd/63ad399a717ee30011337499/16889566511787676720.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    className={`${styles['image_39']} ${styles['pos_40']}`}
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/63a7fdfb5a7e3f0310febbfd/63ad399a717ee30011337499/16889566511752528917.png"
                  />
                </div>
                <div className={`flex-col ${styles['group_20']} ${styles['space-y-23']}`}>
                  <span className={`self-center ${styles['font_3']} ${styles['text_11']}`}>WEB1.0</span>
                  <span className={`${styles['font_2']} ${styles['text_12']}`}>
                    The first generation internet (Web 1.0) was the PC (personal computer) internet, which has improved
                    the efficiency of global information transmission and lowered the threshold for information
                    acquisition since its development in 1994.
                  </span>
                </div>
              </div>
            </div>
            <div className={`flex-col ${styles['group_21']} ${styles['view_2']} ${styles['space-y-40']}`}>
              <div className={`flex-col items-center ${styles['space-y-15']}`}>
                <span className={`${styles['works_font']} `}>How It Works</span>
                <div className={`${styles['section_6']}`}></div>
              </div>
              <div className={`flex-row ${styles['panel-scroll']} ${styles['group_21']} ${styles['space-x-40']}`}>
                <img
                  className={`shrink-0 ${styles['image_40']}`}
                  src="/image/750/home/编组 4备份.png"
                />
                <img
                  className={`shrink-0 ${styles['image_40']}`}
                  src="/image/750/home/编组 14备份.png"                />
                <img
                  className={`shrink-0 ${styles['image_40']}`}
                  src="/image/750/home/编组 16 (1).png"                />
                <img
                  className={`shrink-0 ${styles['image_40']}`}
                  src="/image/750/home/编组 5.png"                />
                <img
                  className={`shrink-0 ${styles['image_40']}`}
                  src="/image/750/home/编组 18 (1).png"                />
              </div>
            </div>
          </div>
        </div>
  
      </div>
    </div>
 
    </StylePanel>
    
  );
};

export default MoblieHome;
