import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import styles from './css/Mobileheader.module.css';
const Center = styled(Box)<any>`
  background-image: url(/image/新建项目.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  border-radius: 10px;
`;
const ImgCenter = styled.img`
  width: 130px;
  height: 130px;
`;
const ImgMint = styled.img`
  padding: 15px;
`;
const HeaderContainer = styled(Box)`
  height: 100px;
  color: #fff;
  background: black;
  border-bottom: 1px solid #3c3c3c;
  margin-bottom: 2px;
  position: fixed;
  width: 100%;
`;
const BoxMenu = styled(Box)``;

const MenuLink = styled(Link)`
  display: flex;
  margin-left: 20px;
  font-size: 1rem;
  font-family: 'Source Han Sans CN';
  line-height: 1rem;
  font-weight: 200;
  color: #000000;
  text-decoration: none;
  margin-right: 20px;
`;

const StyleHeader=styled(Box)<{ isMobile?: boolean }>`
     height:1.04rem;
     position: fixed;
     width: 100%;
`

const BtnLogin= styled.button<any>`
  background: #c1fdd8;
  border-radius: 0.12rem;
  color:#3AB78F;
  padding: 0.16rem;
`;
const BtnStart= styled.button<any>`
  background: linear-gradient(135deg,#36d0ac, #60f770);
  border-radius: 0.12rem;
  color:#fff ;
  padding: 0.16rem;
  :hover{
        color: #36D0AC;
        border: 2px solid transparent;
        background-clip: padding-box, border-box;
        background-origin: padding-box, border-box;
        background-image: linear-gradient(to right, #fff, #fff), linear-gradient(to right, #37d1a9, #5ff671);
  }

`;




const Mobileheader: React.FC = () => {
  const { t } = useTranslation();
  const [active,setActive]=useState<any>(0);
  const [activeSub,setActiveSub]=useState<any>(0);
  const navigate = useNavigate();
  const menu=[{
    label:"/",
    route:"/"
   },
   {
    label:"Learn",
    route:"/Learn/Protocol"
   },

   {
    label:t("Participate"),
    route:"/Developers"
   },
   {
     label:"About",
     route:"/About"
   },
   {
    label:"Community",
    route:"/Community"
   }
 ]
 
 
  const [mune,setMune]=useState(false);
  const handMenu=()=>{
    const muneStatus=!mune;
    if(muneStatus){
      document.body.style.overflow = 'hidden';
    }else{
      document.body.style.overflow = 'auto';
    }
    setMune(muneStatus)
  }

  const handActive=(idx:number)=>{
    setActive(0);
    navigate(menu[idx].route);
    document.body.style.overflow = 'auto';
    setMune(false);
  }
  const handSubActive=(router:string)=>{
    setActive(0);
    setActiveSub(0);
    navigate(router);
    document.body.style.overflow = 'auto';
    setMune(false);
  }
  const handInit=()=>{
    setActive(0);
    setActiveSub(0);
  }

  const learnItem=[
    {label:'Access-Control Protocol',router:`/Learn/Protocol`},
    {label:'Elfin Authorizer',router:`/Learn/Authorizer`},
    {label:'Enfin Recryptor',router:`/Learn/Recryptor`},
    {label:'Enfin Directory',router:`/Learn/Directory`},
    {label:'UTXO Adapter',router:`/Learn/Adapter`},
    {label:'BCH Payment Judger',router:`/Learn/Payment`},
  ]
  const aboutItem=[
    {label:'About Us',router:"/"},
    {label:'Our Roadmap',router:"/"},
    {label:'Blog',router:"/"},
    {label:'News',router:"/"},
  ]
  const developerItem=[
    {label:'Dev Docs',router:"/"},
    {label:'Sample Codes',router:"/"}
  ]
  const communityItem=[
    {label:'Community Hub',router:"/"},
    {label:'Support',router:"/"}
  ]
  const submenu=[learnItem,developerItem,aboutItem,communityItem];
  return (
    
    <div className={`flex-row justify-center items-center  ${styles['header']}`}>

      <div  
         onClick={handMenu}
         className={`cursor-pointer ${styles['pos']} ${styles['hamburger']}  ${mune?styles['is-active']:""}`}  >
        <span className={`${styles['line']}`}></span>
        <span className={`${styles['line']}  ${mune?styles['styleOpacity']:""}`}></span>
        <span className={`${styles['line']}`}></span>
        </div>
 
      <div onClick={()=>{handActive(0)}} className={`cursor-pointer flex-row items-center ${styles['space-x-6']}`}>
        <img
          className={`shrink-0 ${styles['image']}`}
          src="/image/750/home/ElfinGuard Logo_Landscape.jpg"        />
        {/* <span className={`${styles['font_1']} ${styles['text']}`}>ElfinGuard</span> */}
      </div>

      <div className={mune?'block':'hidden'}>
           <div className="absolute top-[1rem] left-[0px] z-10 w-full h-[calc(100vh_-_1rem)] ">
            <div className="bg-white overflow-auto flex-col min-h-0 h-full items-stretch block">
              
              {
                activeSub!==0 && 
                <div className=" h-[50px] flex font-medium bg-[#f8f8f8] py-[6px] px-[10px] text-[13px]  items-center">
                  <img  onClick={handInit} src="/image/750/home/向左箭头 (1).png" className="flex-shrink-0 p-[10px]  box-content select-none" width="16" height="16" alt="arrow"/>
                  <span className="text-[16px] text-[#929292]">Main menu</span>
                  <span className="text-[16px] mx-[5px] text-[#929292]">/</span>
                  <span className="text-[16px] text-[#1b1b1b]">{menu[activeSub].label}</span>
                </div>
              
              }
              
              
              {activeSub!==0  && <>
                {submenu[activeSub-1].map((t)=>(
                    <div key={t.label} className="flex mx-[20px] justify-between items-center pt-[15px]">
                      <a  onClick={()=>{handSubActive(t.router)}}  className="cursor-pointer text-black3-color text-[18px]  font-roboto-regular p-[10px]">{t.label}</a>
                    </div>
                  ))
                }
              </>
                
              }
              
               
              
    

              { active==0 && activeSub==0 &&<>
                  <div className="flex mx-[20px] justify-between items-center pt-[20px]">
                      <a   onClick={()=>{handActive(1)}}  className="flex-1 cursor-pointer text-black3-color text-[18px]  font-roboto-regular p-[10px]">Learn</a>
                      <img onClick={()=>{setActiveSub(1)}} src="/image/750/home/向右箭头.png" className="p-[10px] box-content select-none" width="15" height="15" alt="menu"/>
                  </div>
                  <div className="flex mx-[20px] mt-[10px] justify-between items-center">
                    <a  className="flex-1 cursor-pointer text-black3-color text-[18px]  font-roboto-regular p-[10px]">Developer</a>
                    <img onClick={()=>{setActiveSub(2)}} src="/image/750/home/向右箭头.png" className="p-[10px]  box-content select-none" width="15" height="15" alt="menu"/>
                  </div>

                  <div className="flex mx-[20px] mt-[10px] justify-between items-center">
                    <a onClick={()=>{handActive(3)}} className="flex-1 cursor-pointer text-black3-color text-[18px]  font-roboto-regular p-[10px]">About</a>
                    <img onClick={()=>{setActiveSub(3)}} src="/image/750/home/向右箭头.png" className="p-[10px]  box-content select-none" width="15" height="15" alt="menu"/>
                  </div>

                  <div className="flex mx-[20px] mt-[10px] justify-between items-center">
                    <a  className="flex-1 cursor-pointer text-black3-color text-[18px]  font-roboto-regular p-[10px]">Community</a>
                    <img onClick={()=>{setActiveSub(4)}}  src="/image/750/home/向右箭头.png" className="p-[10px]  box-content select-none" width="15" height="15" alt="menu"/>
                  </div>
                
                  <div className="absolute bottom-[1rem] flex-col   w-full px-[30px]">
                          <BtnLogin className="text-[18px]">Login</BtnLogin>
                          <BtnStart className="text-[18px] mt-[10px]">Get Started</BtnStart>
                  </div>
                    
                </>
              }

                </div>
              </div>


      </div>

    </div>

  );
};

export default Mobileheader;


