import React, { Suspense, lazy, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./style/flexs.css";
import "./style/index.css";

import {Box} from "@material-ui/core";
import styled from "styled-components";
import './i18n';
import useMatchBreakpoints from "./hooks/useMatchBreakpoints";
import DesktopComponent from "./views/pc/DesktopComponent";
import MobileComponent from "./views/mobile/MobileComponent";
import MoblieHome from "./views/mobile/home/MoblieHome";
// import Home from "./views/pc/home/homes";

const Home = lazy(() => import('./views/pc/home/homes'));
const About = lazy(() => import('./views/pc/about/abouts'));
const Learn = lazy(() => import('./views/pc/learn/learn'));

const MobileAbouts = lazy(() => import('./views/mobile/about/MobileAbouts'));
const MoblieLearn = lazy(() => import('./views/mobile/learn/MoblieLearn'));
// const MyCss = lazy(() => import('./MyCss'));

const Center = styled.html`
  @media (max-width: 900px) {
    font-size:12px;
  }
  @media (min-width: 900px) {
    font-size:16px;
  }
`;

const Font = styled(Box)`
  display: flex;
  flex: 1;
  font-family:"-apple-system","BlinkMacSystemFont","Roboto","sans-serif";
`;
// BigNumber.config({
//   EXPONENTIAL_AT: 1000,
//   DECIMAL_PLACES: 80,
// })
const PanelPage = styled(Box)<any>`
  background-image: url(/image/pc/back.png);
  width:100% ;
  height:100% ;
  background-size:100% 100% ;
  background-position:-20% 0%;
`;



const App: React.FC = () => {
  const { isDesktop } = useMatchBreakpoints();
  const location = useLocation();
  useEffect(() => {
    if (document) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (

    <Font>

        <Routes>
            
            <Route path="/" element={<Suspense fallback={null}>{isDesktop?<DesktopComponent />:<MobileComponent/>}</Suspense>} >
            <Route path="/" element={isDesktop?<Home />:<MoblieHome/>} />
            <Route path="/About" element={isDesktop?<About />:<MobileAbouts/>} />
            <Route path='/Learn/:activeId' element={isDesktop?<Learn />:<MoblieLearn/>} />
            <Route path="/user" element={<MoblieHome/>} />

            
            {/* <Route path="/" element={<Layout />} >
            <Route path="/" element={<Home />} />
            <Route path="/Developers" element={<Developers />} />
            <Route path="/Roadmap" element={<Roadmap />} /> */}
        
            {/* <Route path="/Developers" element={<Developers />} />
            <Route path="/Roadmap" element={<Roadmap />} /> */}

            {/* <Route path="/puzzle" element={<PuzzleGame />} />
            <Route path="/IceWater/:id" element={<IceWater />} />
            <Route path="/IceWater" element={<IceWater />} />
            <Route path="/DrawLots/:id" element={<DrawLots />} />
            <Route path="/DrawLots" element={<DrawLots />} />
            <Route path="/Swap" element={<Swap />} /> */}
            
          </Route>
        </Routes>
        
      
      </Font>
   
 
  );
};

export default App;