import { Box, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
// import styles from '../home/css/home.module.css';
import useMatchBreakpoints from "../../../hooks/useMatchBreakpoints";
import styles from './css/footer.module.css';
import { useNavigate } from "react-router-dom";

const FooterPanel=styled(Box)<{ isDesktop?: boolean }>`
    /* margin-top:-6.2rem; */
`
const StylePanel=styled(Box)<{ isDesktop?: boolean }>`
    margin-top:0;
`
const UlStyle=styled(Box)<{ isDesktop?: boolean }>`
    margin-top:0.43rem;
`

const Footer: React.FC = () => {
  const { isDesktop, isMobile } = useMatchBreakpoints();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const learnItem=[
    {label:'Access-Control Protocol',router:`/Learn/Protocol`},
    {label:'Elfin Authorizer',router:`/Learn/Authorizer`},
    {label:'Enfin Recryptor',router:`/Learn/Recryptor`},
    {label:'Enfin Directory',router:`/Learn/Directory`},
    {label:'UTXO Adapter',router:`/Learn/Adapter`},
    {label:'BCH Payment Judger',router:`/Learn/Payment`},
  ]
  return (
    <FooterPanel display={'flex'} flexDirection={'column'} flex={1}>
     <div className={`flex-col ${styles['section_10']}`}>

      <div className="flex-row xl:w-[1240px] lg:w-full lg:px-[80px]   pt-[75px] pb-[82px] justify-around">
 
          <div className={`flex-col  items-center ${styles['group_13']}`}>
            <div  onClick={()=>{navigate('/')}} className={`cursor-pointer flex-row items-center ${styles['space-x-4']}`}>
              <img
                className={`shrink-0 ${styles['image']}`} 
                src="image/PC/home/ElfinGuard Logo_Portrait.jpg"
              />
              {/* <span className={`${styles['text_14']}`}>ElfinGuard</span> */}
            </div>

            <span className={`${styles['text_15']} mt-[35px]`}>
              © 2023 ElfinGuard. <br />
              All rights reserved
            </span>

            <div className={`flex-row ${styles['space-x-26']} mt-[31px]`}>
                <img
                  className={`${styles['image_20']}`}
                  src="image/PC/home/编组 11.png"
                />
                <img
                  className={`${styles['image_20']}`}
                  src="image/PC/home/编组 12.png"
                 />
                <img
                  className={`${styles['image_20']}`}
                  src="image/PC/home/编组 13.png"                />
                <img
                  className={`${styles['image_21']}`}
                  src="image/PC/home/编组_G.png"                 />
              </div>



            {/* <span className={`${styles['font_8']}`}>LEARN</span>
            <span className={`${styles['font_8']}`}>DEVELOPER</span>
            <span className={`${styles['font_8']}`}>ABOUT</span>
            <span className={`${styles['font_8']}`}>COMMUNITY</span> */}
          </div>


          <div className="flex flex-col">
                     <a href="javascript:void(0)" onClick={()=>{navigate('/Learn/Protocol')}} className={`pl-[16px] ${styles['title']}`}>LEARN</a>  
                     {learnItem.map(t=>(
                       <a href="javascript:void(0)" onClick={()=>{navigate(t.router)}} className={`flex-row ${styles['font_9']} mt-[27px]`}>
                        <img  className={`shrink-0 ${styles['image_19']}`}src="image/PC/home/向右箭头.png"/>
                        <span className="ml-[8px]">{t.label}</span> 
                       </a>
                     ))}
                     {/* <a href="javascript:void(0)" className={`flex-row ${styles['font_9']} mt-[27px]`}>
                        <img  className={`shrink-0 ${styles['image_19']}`}src="image/PC/home/向右箭头.png"/>
                        <span className="ml-[8px]">Whitepaper</span> 
                      </a>
                     <a href="javascript:void(0)" className={`flex-row ${styles['font_9']} mt-[36px]`}>
                       <img  className={`shrink-0 ${styles['image_19']}`}src="image/PC/home/向右箭头.png"/>
                       <span className="ml-[8px]">Enfin Authorizer</span> 
                     </a>
                     <a href="javascript:void(0)" className={`flex-row ${styles['font_9']} mt-[36px]`}>
                       <img  className={`shrink-0 ${styles['image_19']}`}src="image/PC/home/向右箭头.png"/>
                       <span className="ml-[8px]">Enfin Recryptor</span> 
                    </a>
                    <a href="javascript:void(0)" className={`flex-row ${styles['font_9']} mt-[36px]`}>
                       <img  className={`shrink-0 ${styles['image_19']}`}src="image/PC/home/向右箭头.png"/>
                       <span className="ml-[8px]">Enfin Directory</span> 
                    </a>
                    <a href="javascript:void(0)" className={`flex-row ${styles['font_9']} mt-[36px]`}>
                       <img  className={`shrink-0 ${styles['image_19']}`}src="image/PC/home/向右箭头.png"/>
                       <span className="ml-[8px]">Enfin DEX</span> 
                    </a> */}
   
                </div>
                
                <div className="flex flex-col">
                     <span className={`pl-[16px] ${styles['title']}`}>DEVELOPER</span>  
                     <a href="javascript:void(0)" className={`flex-row ${styles['font_9']} mt-[27px]`}>
                        <img  className={`shrink-0 ${styles['image_19']}`}src="image/PC/home/向右箭头.png"/>
                        <span className="ml-[8px]">Dev Docs</span> 
                      </a>
                     <a href="javascript:void(0)" className={`flex-row ${styles['font_9']} mt-[36px]`}>
                       <img  className={`shrink-0 ${styles['image_19']}`}src="image/PC/home/向右箭头.png"/>
                       <span className="ml-[8px]">Sample Codes</span> 
                     </a>
   
                </div>

                   
                <div className="flex flex-col">
                     <a href="javascript:void(0)" onClick={()=>{navigate('/About')}} className={`pl-[16px] ${styles['title']}`}>ABOUT</a>  
                     <a href="javascript:void(0)" className={`flex-row ${styles['font_9']} mt-[27px]`}>
                        <img  className={`shrink-0 ${styles['image_19']}`}src="image/PC/home/向右箭头.png"/>
                        <span className="ml-[8px]">About Us</span> 
                      </a>
                     <a href="javascript:void(0)" className={`flex-row ${styles['font_9']} mt-[36px]`}>
                       <img  className={`shrink-0 ${styles['image_19']}`}src="image/PC/home/向右箭头.png"/>
                       <span className="ml-[8px]">Our Roadmap</span> 
                     </a>
                     <a href="javascript:void(0)" className={`flex-row ${styles['font_9']} mt-[36px]`}>
                       <img  className={`shrink-0 ${styles['image_19']}`}src="image/PC/home/向右箭头.png"/>
                       <span className="ml-[8px]">Blog</span> 
                     </a>
                     <a href="javascript:void(0)" className={`flex-row ${styles['font_9']} mt-[36px]`}>
                       <img  className={`shrink-0 ${styles['image_19']}`}src="image/PC/home/向右箭头.png"/>
                       <span className="ml-[8px]">News</span> 
                     </a>
   
                </div>
             


                <div className="flex flex-col">
                     <span className={`pl-[16px] ${styles['title']}`}>COMMUNITY</span>  
                     <a href="javascript:void(0)" className={`flex-row ${styles['font_9']} mt-[27px]`}>
                        <img  className={`shrink-0 ${styles['image_19']}`}src="image/PC/home/向右箭头.png"/>
                        <span className="ml-[8px]">Community Hub</span> 
                      </a>
                     <a href="javascript:void(0)" className={`flex-row ${styles['font_9']} mt-[36px]`}>
                       <img  className={`shrink-0 ${styles['image_19']}`}src="image/PC/home/向右箭头.png"/>
                       <span className="ml-[8px]">Support</span> 
                     </a>
                </div>

          
        
      </div>


    </div>
    </FooterPanel>
  
    
  );
};

export default Footer;
