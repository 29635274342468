import { Box, Button } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import styles from './css/MobileFooter.module.css';

const Center = styled(Box)<any>`
  background-image: url(/image/新建项目.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  border-radius: 10px;
`;
const ImgCenter = styled.img`
  width: 130px;
  height: 130px;
`;
const ImgMint = styled.img`
  padding: 15px;
`;
const HeaderContainer = styled(Box)`
  height: 100px;
  color: #fff;
  background: black;
  border-bottom: 1px solid #3c3c3c;
  margin-bottom: 2px;
  position: fixed;
  width: 100%;
`;
const BoxMenu = styled(Box)``;

const MenuLink = styled(Link)`
  display: flex;
  margin-left: 20px;
  font-size: 1rem;
  font-family: 'Source Han Sans CN';
  line-height: 1rem;
  font-weight: 200;
  color: #000000;
  text-decoration: none;
  margin-right: 20px;
`;

const StyleHeader=styled(Box)<{ isMobile?: boolean }>`
     height:1.04rem;
     /* width: 1rem; */
     /* background-color: #001634; */
`

const BoxImage=styled(Box)<{ isMobile?: boolean }>`
  /* width: ${(props) => (props.isMobile?"0.32rem":"24px")};
  height: ${(props) => (props.isMobile?"0.27rem":"24px")};
  padding:${(props) => (props.isMobile ? "0rem 0.31rem 0rem 0.28rem" : "18px 24px 18px 24px")} ; */
`
const StyleMenuBox=styled(Box)`
    .active{
        animation: activeAnmine 0.5s ease-in-out both 0.5s 1;
    }
      
    @keyframes  activeAnmine {
      0% {
        opacity: 0.1;
      }
      /* 50% {
        opacity: 0.5;
      } */
      100% {
        opacity: 1;
      }
  }

`

const StyleBtnMenu=styled(Box)` 
  cursor: pointer;
  position: relative;
  justify-content:center ;


  .active{
          animation: activeAnmine 0.5s ease-in-out both 0s 1;
  }
      
    @keyframes  activeAnmine {
      0% {
        opacity: 0.5;
      }
      50% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
      }
  }
`
const StyleActive=styled(Box)` 
  width:100%;
  height:3px;
  background-color: #0e9c5d;
  position:absolute;
  bottom:0px;
  opacity: 0;
`


const LogBox=styled(Box)`
  @media (max-width: 750px) {
       height: 0.54rem;
       width: 1.62rem;
  }

  @media (min-width:750px){
      height: 36px;
      width:  108px;
  }
`


const MobileFooter: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const learnItem=[
    {label:'Access-Control Protocol',router:`/Learn/Protocol`},
    {label:'Elfin Authorizer',router:`/Learn/Authorizer`},
    {label:'Enfin Recryptor',router:`/Learn/Recryptor`},
    {label:'Enfin Directory',router:`/Learn/Directory`},
    {label:'UTXO Adapter',router:`/Learn/Adapter`},
    {label:'BCH Payment Judger',router:`/Learn/Payment`},
  ]
  return (
    <div className={`flex-col flex-1 ${styles['section_21']}`}>

     

      <div className={`flex-col ${styles['space-y-14']}`}>
        <div  onClick={()=>{navigate('/')}} className={`cursor-pointer flex-row justify-center items-center ${styles['space-x-4']}`}>
          <img
            className={`${styles['image_41']}`}
            src="/image/PC/home/ElfinGuard Logo_Portrait.jpg"
          />
          {/* <span className={`${styles['font_1']}`}>ElfinGuard</span> */}
        </div>
        <span className={`self-center ${styles['text_13']}`}>© 2023 ElfinGuard. All rights reserved</span>
      </div>


      <div className="flex-row flex-1  pl-[1rem] pr-[1rem] mt-[0.56rem]">
         <div className="flex-col flex-1 justify-between">
              <div className="flex-col ">
                  <a href="javascript:void(0)" onClick={()=>{navigate('/Learn/Protocol')}} className={`${styles['font_1']}`}>LEARN</a>
                  {learnItem.map(t=>(
                    <div onClick={()=>{navigate(t.router,{preventScrollReset:false})}} className={`flex-row items-center ${styles['space-x-4']} mt-[0.024rem]`}>
                      <img
                        className={`shrink-0 ${styles['image_42']}`}
                        src=" image/750/home/向右箭头1.png"
                      />
                      <a href="javascript:void(0)" className={`${styles['font_8']}`}>
                        {t.label=='Access-Control Protocol' && <span className={`${styles['line-4']}`}>Access-Control<br/>Protocol</span>}
                        {t.label=='BCH Payment Judger' && <span className={`${styles['line-4']}`}>BCH Payment<br/>Judger</span>}
                        {t.label!='Access-Control Protocol'&& t.label!='BCH Payment Judger'&& t.label}
                      </a>
                  </div>

                  ))}
                  
              </div>

              <div className="flex-col mt-[0.8rem]">
                  <span className={`${styles['font_1']}`}>DEVELOPER</span>
                  <div className={`flex-row items-center ${styles['space-x-4']} mt-[0.024rem]`}>
                      <img
                        className={`shrink-0 ${styles['image_42']}`}
                        src=" image/750/home/向右箭头1.png"
                      />
                      <a href="javascript:void(0)" className={`${styles['font_8']}`}>Dev Docs</a>
                  </div>
                  <div className={`flex-row items-center ${styles['space-x-4']}`}>
                      <img
                        className={`shrink-0 ${styles['image_42']}`}
                        src=" image/750/home/向右箭头1.png"
                      />
                      <a href="javascript:void(0)" className={`${styles['font_8']}`}>Sample Codes</a>
                  </div>
              </div>



         </div>
         <div className="flex-col  justify-between">
              <div className="flex-col">
                  <a  href="javascript:void(0)" onClick={()=>{navigate('/About')}} className={`${styles['font_1']}`}>ABOUT</a>
             
                  
                  <div className={`flex-row items-center ${styles['space-x-4']} mt-[0.024rem]`}>
                      <img
                        className={`shrink-0 ${styles['image_42']}`}
                        src=" image/750/home/向右箭头1.png"
                      />
                      <a href="javascript:void(0)" className={`${styles['font_8']}`}>About Us</a>
                  </div>

                  <div className={`flex-row items-center ${styles['space-x-4']}`}>
                      <img
                        className={`shrink-0 ${styles['image_42']}`}
                        src=" image/750/home/向右箭头1.png"
                      />
                      <a href="javascript:void(0)" className={`${styles['font_8']}`}>Our Roadmap</a>
                  </div>


                  <div className={`flex-row items-center ${styles['space-x-4']}`}>
                      <img
                        className={`shrink-0 ${styles['image_42']}`}
                        src="image/750/home/向右箭头1.png"
                      />
                      <a href="javascript:void(0)" className={`${styles['font_8']}`}>Blog</a>
                  </div>
                  <div className={`flex-row items-center ${styles['space-x-4']}`}>
                      <img
                        className={`shrink-0 ${styles['image_42']}`}
                        src="image/750/home/向右箭头1.png"
                      />
                      <a href="javascript:void(0)" className={`${styles['font_8']}`}>News</a>
                  </div>
              </div>
              <div className="flex-col mt-[0.8rem]">
                  <span className={`${styles['font_1']}`}>COMMUNITY</span>
                  <div className={`flex-row items-center ${styles['space-x-4']} mt-[0.024rem]`}>
                      <img
                        className={`shrink-0 ${styles['image_42']}`}
                        src="image/750/home/向右箭头1.png"
                      />
                      <a href="javascript:void(0)" className={`${styles['font_8']}`}>Community Hub</a>
                  </div>
                  <div className={`flex-row items-center ${styles['space-x-4']}`}>
                      <img
                        className={`shrink-0 ${styles['image_42']}`}
                        src="image/750/home/向右箭头1.png"
                      />
                      <a href="javascript:void(0)" className={`${styles['font_8']}`}>Support</a>
                  </div>
              </div>
         </div>
      </div>
 
      <div className={`flex-row justify-center ${styles['group_33']} ${styles['space-x-32']}`}>
        <img
          className={`${styles['image_48']}`}
          src="image/750/home/编组 11.png"        />
        <img
          className={`${styles['image_48']}`}
          src="image/750/home/编组 12.png"        />
        <img
          className={`${styles['image_48']}`}
          src="image/750/home/编组 13.png"        />
        <img
          className={`${styles['image_49']}`}
          src="image/750/home/编组 14.png"        />
      </div>
    </div>
  );
};

export default MobileFooter;


