import { Box, Button } from "@material-ui/core";
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
// import styles from '../home/css/home.module.css';
import useMatchBreakpoints from "../../../hooks/useMatchBreakpoints";
import styles from './css/header.module.css';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';


const StyleHeader=styled(Box)<{ isFixed?: boolean }>`
     position:fixed;
     top:0;
     background: ${(props) => (props.isFixed ? "#fff" : "")} ;
     width: 100%;
     z-index: 999;
     border:${(props) => (props.isFixed ? "1px solid #cccccc85" : "")} ;

     /* box-shadow:0px 0px 10px rgb(0 0 0 / 52%); */
     /* box-shadow:${(props) => (props.isFixed ? "0px 0px 10px rgb(0 0 0 / 52%)" : "")} ; */

     /*padding-top:${(props) => (props.isFixed ? "0.3rem" : "0rem")} ;
     padding-bottom:${(props) => (props.isFixed ? "0.3rem" : "0")} ; */
     /* padding-left:8%;
     padding-right:8%; */
`
const BtnLogin= styled.button<any>`
  width: 73px;
  height: 36px;
  background: #c1fdd8;
  border-radius: 4px;
  color:#3AB78F;
`;
const BtnStart= styled.button<any>`
  width: 112px;
  height: 38px;
  background: linear-gradient(135deg,#36d0ac, #60f770);
  border-radius: 4px;
  color:#fff ;
  :hover{
        color: #36D0AC;
        border: 2px solid transparent;
        background-clip: padding-box, border-box;
        background-origin: padding-box, border-box;
        background-image: linear-gradient(to right, #fff, #fff), linear-gradient(to right, #37d1a9, #5ff671);
  }

`;
const StyleArrowDropDownIcon=styled(ArrowDropDownIcon)<{ isFixed?: boolean }>`
      &&.MuiSvgIcon-root{
        font-size:16px;
      }
`
const ItemMenu= styled.div`

  .item-menu-panel{
      font-size: 18px;
      border-radius: 5px;
      margin-left: -10% !important;
      display: none;
      /* border: 1px solid red; */
  }
  .item-menu-panel>div{
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  }


  :hover{
    .item-menu-panel{
      display: block;
    }
  }
`;
const MenuPanel= styled.div`
`;




const Header: React.FC = () => {
  const [scrollPosition, setScrollPosition] = useState(false);

  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const htmlFontSizeInPx=parseFloat(getComputedStyle(document.documentElement).fontSize);
   
  
    if ((scrollTop/htmlFontSizeInPx) >= 0.1) {
        setScrollPosition(true);
    }else{
        setScrollPosition(false);
    }
  };
  useEffect(() => {
    
  
    window.addEventListener('scroll', handleScroll,true);
    
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll,true);
    };
  }, []);




  
  const { isDesktop, isMobile } = useMatchBreakpoints();
  const { t } = useTranslation();
  const [active,setActive]=useState<any>(0);
  const navigate = useNavigate();
  const menu=[{
    label:"/",
    route:"/"
   },{
     label:"About",
     route:"/About"
   }
   ,
   {
     label:"Learn",
     route:"/Learn/Protocol"
   },
   {
     label:t("Participate"),
     route:"/Developers"
   }
 ]

  const handActive=(idx:number)=>{
        setActive(idx);
        navigate(menu[idx].route);
        window.scrollTo(0, 0);
  }
  const handSubActive=(router:string)=>{
        navigate(router);
        window.scrollTo(0, 0);
  }
  const learnItem=[
    {label:'Access-Control Protocol',router:`/Learn/Protocol`},
    {label:'Elfin Authorizer',router:`/Learn/Authorizer`},
    {label:'Enfin Recryptor',router:`/Learn/Recryptor`},
    {label:'Enfin Directory',router:`/Learn/Directory`},
    {label:'UTXO Adapter',router:`/Learn/Adapter`},
    {label:'BCH Payment Judger',router:`/Learn/Payment`},
  ]

  return (
    <StyleHeader isFixed={scrollPosition}>
      <div className={`flex-row justify-between items-center ${styles['section']} `}>
 
        <div className={`flex-1 flex-row items-center justify-between ${styles['space-x-117']}`}>
          
          <div onClick={()=>{handActive(0)}} className={`cursor-pointer flex-row items-center ${styles['space-x-4']}`}>
            <img
              className={`shrink-0 ${styles['image']}`}
              src="image/PC/home/ElfinGuard Logo_Portrait.jpg"
            />
            {/* <span className={`${styles['text']}`}>ElfinGuard</span> */}
          </div>

          <MenuPanel  className={`flex-row ${styles['space-x-80']} relative `}>
            
            <ItemMenu 
              className={`cursor-pointer flex-row items-center ${styles['space-x-6']}`}>
              <div onClick={()=>{handActive(2)}} >
                <span className={`${styles['font_1']}`}>Learn</span>
                <StyleArrowDropDownIcon></StyleArrowDropDownIcon>
              </div>
             


       
              <div className="left-[0px] top-[20px] pt-[32px] ml-[-5%] item-menu-panel  absolute  w-[120%]">
                  <div className="p-[10px]  w-[100%] bg-white rounded-lg  ">
                    {learnItem.map((t)=>(
                            <div className="flex cursor-pointer p-[16px] rounded-lg hover:bg-gray-100">
                            <div className="flex flex-1 pt-1 pb-1" onClick={()=>{handSubActive(t.router)}}>
                              <img className="mr-4 w-[20px] h-[20px]" src="image/PC/home/ItemArrow.png" alt="image/PC/home/ItemArrow.png" />
                              <a className="flex-1">{t.label}</a>
                            </div>
                           </div>
                     ))}
                    </div>
                   
              </div> 
            
            </ItemMenu>

            <ItemMenu className={`cursor-pointer flex-row items-center ${styles['space-x-4']}`}>
                <div >
                  <span className={`${styles['font_1']}`}>Developer</span>
                  <StyleArrowDropDownIcon></StyleArrowDropDownIcon>
                </div>
                <div className="left-[0px] top-[20px] pt-[32px] ml-[-5%]  item-menu-panel absolute  w-[120%]">
                      <div className="p-[10px]  w-[100%] bg-white rounded-lg ">
                         {['Dev Docs','Sample Codes'].map((t)=>(
                            <div className="flex cursor-pointer p-[16px] rounded-lg hover:bg-gray-100">
                            <div className="flex flex-1 pt-1 pb-1">
                              <img className="mr-4 w-[20px] h-[20px]" src="image/PC/home/ItemArrow.png" alt="image/PC/home/ItemArrow.png" />
                              <a className="flex-1">{t}</a>
                            </div>
                           </div>
                         ))}
                      </div>
                </div>
            </ItemMenu>

            <ItemMenu  className={`cursor-pointer flex-row items-center ${styles['space-x-6']}`}>
              <div onClick={()=>{handActive(1)}}>
                <span className={`${styles['font_1']}`}>About</span>
                <StyleArrowDropDownIcon></StyleArrowDropDownIcon>
              </div>
              
              <div className="left-[0px] top-[20px] pt-[32px] ml-[-5%]  item-menu-panel absolute  w-[120%]">
                      <div className="p-[10px]  w-[100%] bg-white rounded-lg ">
                        {['About Us','Our Roadmap','Blog','News'].map((t)=>(
                            <div className="flex cursor-pointer p-[16px] rounded-lg hover:bg-gray-100">
                            <div className="flex flex-1 pt-1 pb-1">
                              <img className="mr-4 w-[20px] h-[20px]" src="image/PC/home/ItemArrow.png" alt="image/PC/home/ItemArrow.png" />
                              <a className="flex-1">{t}</a>
                            </div>
                           </div>
                         ))}
                      </div>
              </div>
            </ItemMenu>
            <ItemMenu className={`cursor-pointer flex-row items-center ${styles['space-x-4']}`}>
              <div>
                  <span className={`${styles['font_1']}`}>Community</span>
              </div>
              
              <StyleArrowDropDownIcon></StyleArrowDropDownIcon>
              <div className="left-[0px] top-[20px] pt-[32px] ml-[-5%]  item-menu-panel absolute  w-[120%]">
                      <div className="p-[10px]  w-[100%] bg-white rounded-lg ">
                        {['Community Hub','Support'].map((t)=>(
                            <div className="flex cursor-pointer p-[16px] rounded-lg hover:bg-gray-100">
                            <div className="flex flex-1 pt-1 pb-1">
                              <img className="mr-4 w-[20px] h-[20px]" src="image/PC/home/ItemArrow.png" alt="image/PC/home/ItemArrow.png" />
                              <a className="flex-1">{t}</a>
                            </div>
                           </div>
                         ))}
                      </div>
              </div>
            </ItemMenu>
      
            
           
           

          </MenuPanel>

          <div className={`flex-row ${styles['space-x-16']}`}>
            <BtnLogin>Login</BtnLogin>
            <BtnStart>Get Started</BtnStart>
            {/* <img
              className={`${styles['image_2']}`}
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/63a7fdfb5a7e3f0310febbfd/63ad399a717ee30011337499/1a7a2b60d6720d9e2e2642d9e65126fd.png"
            />
            <img
              className={`${styles['image_3']}`}
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/63a7fdfb5a7e3f0310febbfd/63ad399a717ee30011337499/722256769b1a93bebf10fcb6d9986018.png"
            /> */}
          </div>
        </div>

       
      </div>

    
    
    </StyleHeader>
   
  )
};

export default Header;

